
.rarities {
  padding: 12px;
}

.rarities h2{
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  color: white;
}

.rarity-exploer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rarity-exploer .viverorc .viveinfo {
  display: flex;
  padding: 0px;
  height: 44px;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  top: 0px;
  font-family: "EXEPixelPerfect";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 152%;
}


.rarity-exploer .viverorc {
  background-size: 100% 100% !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
 
  background: #1E202B;
  border-radius: 15px 15px 20px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  max-width: 480px;
  max-height: 480px;
  position: relative;
}
.rarity-exploer .viverorc::after  {
  display: block;
  content: "";
  padding-bottom: 100%;
}


.rarity-exploer .viverorc .viveinfo div:first-child {
  color: #dfdfdf;
}
.rarity-exploer .viverorc .viveinfo div:last-child {
  color: #dfdfdf;
}

.rarity-exploer .viverorc .viveinfo div {
  text-align: center;
  padding: 10px 10px;
}

.rarity-exploer .viverorc .bg-bl {
  opacity: 0.8;
  background: #1E202B;
  height: 48px;
  border-radius: 0px 0px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rarity-exploer .viverorc a {
  transition: 0.5s;
  margin: 10px;
}

.rarity-exploer-check {
  width: 400px;
  margin-left: 20px;
}
@media (max-width: 900px) {
  .rarity-exploer-check {
    margin-left: 0px;
    width: 100%;
    max-width: 500px;
  }
}


.rarity-exploer-check h2 {
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  color: white;
}

.rarity-exploer .rarity-list {
  border-radius: 10px;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  padding-right: 10px;
}
@media (max-width: 985px) {
  .rarity-exploer .rarity-list {
    height: auto;
    padding-top: 10px;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    padding-right: 10px;
    width: auto;
  }
}

.rarity-item:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-top: 20px;
}

.rarity-top {
  text-transform: uppercase;
  color: #7e5656;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-family: "EXEPixelPerfect";
  font-style: normal;
  font-size: 20px;
}

.rarity-item {
  padding-top: 10px;
  height: 64px;
  padding-bottom: 10px;
  background: #242424;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
}
.rarity-list {
  display: flex;
  justify-content: space-between;
}

.rarity-list2 {
  display: flex;
  justify-content: space-between;
}

.helper-common span {
  border: 1px solid #9A9A9A;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(118, 118, 118, 0.3) 0%, #9A9A9A 100%);
}

.helper-common .num {
  border: 1px solid #9A9A9A;
  display: inline-block;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(240, 140, 125, 0.072) 0%, rgba(244, 164, 127, 0.24) 100%);
}


.helper-uncommon span {
  border: 1px solid #88F070;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(118, 118, 118, 0.3) 0%, #88F070 100%);
}

.helper-uncommon .num {
  border: 1px solid #88F070;
  display: inline-block;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(240, 140, 125, 0.072) 0%, rgba(244, 164, 127, 0.24) 100%);
}

.helper-rare span {
  border: 1px solid #50B9FF;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(118, 118, 118, 0.3) 0%, #50B9FF 100%);
}

.helper-rare .num {
  border: 1px solid #50B9FF;
  display: inline-block;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(240, 140, 125, 0.072) 0%, rgba(244, 164, 127, 0.24) 100%);
}


.helper-epic span {
  border: 1px solid #854AFF;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(118, 118, 118, 0.3) 0%, #854AFF 100%);
}

.helper-epic .num {
  border: 1px solid #854AFF;
  display: inline-block;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(240, 140, 125, 0.072) 0%, rgba(244, 164, 127, 0.24) 100%);
}

.helper-legendary span {
  border: 1px solid #ebb512;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(118, 118, 118, 0.3) 0%, #ebb512 100%);
}

.helper-legendary .num {
  border: 1px solid #ebb512;
  display: inline-block;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(240, 140, 125, 0.072) 0%, rgba(244, 164, 127, 0.24) 100%);
}

.helper-mythic span {
  border: 1px solid #FF0050;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(118, 118, 118, 0.3) 0%, #FF0050 100%);
}

.helper-mythic .num {
  border: 1px solid #FF0050;
  display: inline-block;
  background: radial-gradient(139.9% 168.2% at 51.92% 56.25%, rgba(240, 140, 125, 0.072) 0%, rgba(244, 164, 127, 0.24) 100%);
}

.rarity-top .num {
  border-radius: 0px 5px 5px 0px;
  transition: 0.5s;
  font-family: "EXEPixelPerfect";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 152%;
  text-align: center;
  color: #F6F6F6;
  text-transform: capitalize;
  padding: 5px;
  width: 50px;
}
 .rarity-top span{
  display: inline-block;
  transition: 0.5s;
  font-family: "EXEPixelPerfect";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 152%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #F6F6F6;
  text-transform: capitalize;
  padding: 5px;
  border-radius: 5px 0px 0px 5px;
  letter-spacing: 0.01em;
}

.rarity-bottom {
  margin-top: 8px;
}
.progress-bar.progress-rarity {
  height: 6px;
  border: 1px solid #ffffff;
}
.progress-bar {
  height: 16px;
  position: relative;
  background: #282D3B;
  box-shadow: inset 0px 0px 12px #3b2828;
  border: 2px solid #3A3E52;
  border-radius: 70px;
}
.progress-bar.progress-common span {
  background: #9A9A9A;
  filter: drop-shadow(0px 0px 10px #9A9A9A);
}

.progress-bar.progress-uncommon span {
  background: #88F070;
  filter: drop-shadow(0px 0px 10px #88F070);
}

.progress-bar.progress-rare span {
  background: #50B9FF;
  filter: drop-shadow(0px 0px 10px #50B9FF);
}

.progress-bar.progress-epic span {
  background: #854AFF;
  filter: drop-shadow(0px 0px 10px #854AFF);
}

.progress-bar.progress-legendary span {
  background: #F0C87B;
  filter: drop-shadow(0px 0px 10px #F0C87B);
}

.progress-bar.progress-mythic span {
  background: #FF0050;
  filter: drop-shadow(0px 0px 10px #FF0050);
}

.progress-bar span {
  display: block;
  height: 100%;
  border-radius: 70px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}
.progress-bar span {
  max-width: 100%;
  transition: 0.5s;
}

.rarity-top span.helper {
  background: rgba(109, 85, 85, 0.24);
  border: 1px solid #6d5555;
  border-radius: 5px;
  text-align: center;
  width: 50px;
}

.progress-bar .progress-light span{
  background: #F3F3F3;
  filter: drop-shadow(0px 0px 10px #F3F3F3);
}